<template>
  <el-dialog
    title="前测定级"
    :visible="visible"
    width="50%"
    :destroy-on-close="true"
    :lock-scroll="false"
    custom-class="ele-dialog-form"
    @update:visible="updateVisible"
  >
    <el-collapse class="el-collapse" accordion>

      <el-collapse-item class="el-collapse-item" v-for="(item,index) in list" :key="item.key">
        <!-- {{item.code}} -->
        <template slot="title">
          <el-col :span="6" :offset="1"><div>{{ item.title }}</div></el-col>
          <el-col :span="6"><div>正确率 {{ item.correct }}%</div></el-col>
          <el-col :span="6"><div>时长 {{ item.time }}</div></el-col>
        </template>
        <div v-if="['quantityliteracy'].includes(item.code)" >
          <div class="topic_content" v-for="(lits,index1) in item.question" :key="lits.key">
            <!-- 题目 -->
            <el-row >
              <el-col :span="2" :offset="1">
                <div>{{ index1+1 }}/{{ item.question.length }}</div>
              </el-col>
              <el-col :span="20" :offset="1">
                <div>{{ lits.question }}</div>
              </el-col>
            </el-row>
            <el-row style="min-height: 83px; align-items: center" type="flex">

              <el-col :span="21" :offset="2" v-if="lits.is_right">

                <el-radio disabled  v-model="+lits.user_answer"  v-for="(value,index2) in lits.answer" :label="value.id">{{ value.answer_content }}</el-radio>

              </el-col>
              <el-col :span="21" :offset="2" v-else>
                <el-radio disabled  v-for="(value,index2) in lits.answer" v-if="value.id == lits.user_answer" v-model="+value.id" :label="value.id" class="text">
                  {{ value.answer_content }}
                </el-radio>

                <el-radio disabled  v-else v-model="+lits.answer_id"  :label="value.id" >
                  {{ value.answer_content }}
                </el-radio>

              </el-col>
            </el-row>
          </div>
        </div>

        <div v-else-if="['wordlogic'].includes(item.code)" >
          <div class="topic_content" v-for="(items,index1) in item.question" :key="items.key">
            <!-- 题目 -->
            <el-row   v-for="(lits,index2) in items.question" :key="lits.key">
              <el-row style="min-height: 83px; align-items: center" type="flex">
                <el-col :span="2" :offset="2">
                  <div>{{ index2+1 }}/{{ items.question.length }}</div>
                </el-col>
                <el-col :span="18">
                  <div v-html="lits.question"></div>
                </el-col>
              </el-row>
              <el-row style="min-height: 83px; align-items: center" type="flex">
                <el-col :span="18" :offset="3" v-if="lits.is_right">

                  <el-radio disabled  v-model="+lits.user_answer"  v-for="(value,index3) in lits.answer" :label="value.id">{{ value.answer_content }}</el-radio>

                </el-col>
                <el-col :span="18" :offset="3" v-else>
                  <el-radio disabled  v-for="(value,index3) in lits.answer" v-if="value.id == lits.user_answer" v-model="+value.id" :label="value.id" class="text">
                    {{ value.answer_content }}
                  </el-radio>

                  <el-radio disabled  v-else v-model="+lits.answer_id"  :label="value.id" >
                    {{ value.answer_content }}
                  </el-radio>

                </el-col>
              </el-row>
            </el-row>

          </div>
        </div>

        <div v-else-if="['scene'].includes(item.code)">
          <div class="topic_content" v-for="(stems,index1) in item.question" :key="stems.key">
            <!-- 题目 -->
            <el-row >
              <el-col :span="2" :offset="1">

                <div>{{ index1+1 }}/{{ item.question.length }}</div>
              </el-col>
              <el-col :span="20" :offset="1">
                <div v-html="stems.content"></div>
              </el-col>
            </el-row>

            <el-row   v-for="(lits,index2) in stems.question" :key="lits.key">

              <el-row style="min-height: 83px; align-items: center" type="flex">
                <el-col :span="2" :offset="3">
                  <div>{{ index2+1 }}/{{ stems.question.length }}</div>
                </el-col>
                <el-col :span="18">
                  <div v-html="lits.question"></div>
                </el-col>
              </el-row>

              <el-row style="min-height: 83px; align-items: center; " >
                <el-col :span="20" :offset="5" style="display: flex; align-items: flex-end; align-content:flex-start">
                  <el-radio style="margin: 10px; width:300px; " disabled  v-for="(value,index3) in lits.answer" v-if="value.is_right == 1" v-model="+value.id" :label="value.id">
                    <img style="width: 40%;"  :src="$http.defaults.baseIMGURL + value.answer_content" class="avatar">
                  </el-radio>

                  <el-radio style="margin: 10px ; width:300px" disabled  v-else v-model="+value.id"  :label="value.id" class="text">
                    <img style="width: 40%"  :src="$http.defaults.baseIMGURL + value.answer_content" class="avatar">
                  </el-radio>
                </el-col>
              </el-row>
            </el-row>


          </div>
        </div>
        <div v-else-if="['read','namespeed'].includes(item.code)" >
          <div class="topic_content" v-for="(stems,index1) in item.question" :key="stems.key">

            <!-- 题目 -->
            <el-row >
              <el-col :span="2" :offset="1">

                <div>{{ index1+1 }}/{{ item.question.length }}</div>
              </el-col>
              <el-col :span="20" :offset="1">
                <div v-html="stems.content"></div>
              </el-col>
            </el-row>

            <el-row   v-for="(lits,index2) in stems.question" :key="lits.key">
              <el-row style="min-height: 63px; align-items: center" type="flex">
                <el-col :span="20" :offset="4">
                  <div><span>{{lits.question}}:{{lits.score}}</span></div>
                </el-col>
              </el-row>
            </el-row>
            <el-row >
              <el-col :span="20" :offset="4">
                <div><audio :src="$baseImgUrl +stems.video_path" controls></audio></div>
              </el-col>
            </el-row>

          </div>
        </div>

        <div v-else-if="['eyes'].includes(item.code)">
          <div class="topic_content" v-for="(stems,index1) in item.question" :key="stems.key">

            <!-- 题目 -->
            <el-row >
              <el-col :span="2" :offset="1">

                <div>{{ index1+1 }}/{{ item.question.length }}</div>
              </el-col>
              <el-col :span="20" :offset="1">
                <div v-html="stems.content"></div>
              </el-col>
            </el-row>

            <el-row   v-for="(lits,index2) in stems.question" :key="lits.key">
              <el-row style="min-height: 83px; align-items: center" type="flex">
                <el-col :span="2" :offset="3">
                  <div>{{ index2+1 }}/{{ stems.question.length }}</div>
                </el-col>
                <el-col :span="18">
                  <div v-html="lits.question"></div>
                </el-col>
              </el-row>

              <el-row style="min-height: 83px; align-items: center" type="flex">
                <el-col :span="20" :offset="5" v-if="lits.is_right">
                  <el-radio style="margin: 10px;"  disabled  v-model="+lits.user_answer"  v-for="(value,index3) in lits.answer" :label="value.id">{{ value.answer_content }}</el-radio>

                </el-col>

                <el-col :span="18" :offset="5" v-else>
                  <el-radio style="margin: 10px;" disabled  v-for="(value,index3) in lits.answer" v-if="value.id == lits.user_answer" v-model="+value.id" :label="value.id" class="text">
                    {{ value.answer_content }}
                  </el-radio>
                  <el-radio style="margin: 10px;" disabled  v-else v-model="+lits.rightselect"  :label="value.id" >
                    {{ value.answer_content }}
                  </el-radio>
                </el-col>
              </el-row>
            </el-row>


          </div>
        </div>

        <div v-else>
          <div class="topic_content" v-for="(stems,index1) in item.question" :key="stems.key">

            <!-- 题目 -->
            <el-row >
              <el-col :span="2" :offset="1">

                <div>{{ index1+1 }}/{{ item.question.length }}</div>
              </el-col>
              <el-col :span="20" :offset="1">
                <div v-html="stems.content"></div>
              </el-col>
            </el-row>

            <el-row   v-for="(lits,index2) in stems.question" :key="lits.key">
              <el-row style="min-height: 83px; align-items: center" type="flex">
                <el-col :span="2" :offset="3">
                  <div>{{ index2+1 }}/{{ stems.question.length }}</div>
                </el-col>
                <el-col :span="18">
                  <div v-html="lits.question"></div>
                </el-col>
              </el-row>

              <el-row style="min-height: 83px; align-items: center" type="flex">
                <el-col :span="20" :offset="5" v-if="lits.is_right">
                  <el-radio style="margin: 10px;"  disabled  v-model="+lits.user_answer"  v-for="(value,index3) in lits.answer" :label="value.id">{{ value.answer_content }}</el-radio>

                </el-col>

                <el-col :span="18" :offset="5" v-else>
                  <el-radio style="margin: 10px;" disabled  v-for="(value,index3) in lits.answer" v-if="value.id == lits.user_answer" v-model="+value.id" :label="value.id" class="text">
                    {{ value.answer_content }}
                  </el-radio>
                  <el-radio style="margin: 10px;" disabled  v-else v-model="+lits.answer_id"  :label="value.id" >
                    {{ value.answer_content }}
                  </el-radio>
                </el-col>
              </el-row>
            </el-row>


          </div>
        </div>
      </el-collapse-item>


    </el-collapse>
    <el-row style="margin-top: 21px">
      <el-form
        ref="form"
        :rules="rules"
        label-position="left"
        :model="form"
        label-width="60px"
      >
        <el-form-item label="等级" prop="test_level">
          <el-select v-model="form.test_level" clearable placeholder="请选择" filterable v-if="test_level != 0" >
            <el-option
              v-for="item in levelList"
              :key="item.value"
              :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="form.test_level" clearable placeholder="请选择" filterable v-else >
            <el-option
              v-for="item in levelList"
              :key="item.value"
              :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-row>
    <div slot="footer">
      <el-button @click="resetForm">取消 </el-button>
<!--      <el-button type="primary" @click="preservation" :loading="loading" v-if="test_level == 0">保存-->
      <el-button type="primary" @click="preservation" :loading="loading">保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "beforEdit",
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 父组件方法，关闭弹框
    openEdit: Function,
  },
  data() {
    return {
      // 提交状态
      loading: false,
      radio: 71,
      test_level:0,
      form: Object.assign({}, this.data),
      // 校验
      rules: {
        test_level: [{ required: true, message: "请选择等级", trigger: "change" }],
      },
      list:[],
      levelList:[]
    };
  },
  watch: {
    //deep: true,
    data() {
      if(this.data){
        this.$http['get']('/Admin/getBeforeTestInfo/'+ this.data.id).then(res => {
          if(res.data.data.list){
            this.list = res.data.data.list;
          }
        });
        this.test_level = this.data.test_level;
        this.form = Object.assign({}, this.data);
      }else{
        this.form = Object.assign({});
      }
    }
  },
  mounted() {
    this.queryLevelList()
  },
  methods: {
    queryLevelList() {
      this.$http.get('/Admin/level').then(res => {

        if (res.data.code === 200) {
          this.levelList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      });
    },
    // 保存
    preservation() {
      this.$refs.form.validate((valid) => {

        if (valid) {
          var ruleForm = {
            'test_id':this.data.id,
            'test_level':this.form.test_level,
            'question':[]
          }
          for(let key  in this.list){
            ruleForm.question[key] = {
              'correct':this.list[key].correct,
              'code':this.list[key].code,
            }
          }
          this.$http['post']('/Admin/saveBeforeTest',ruleForm).then(res => {
            this.loading = false;
            if (res.data.code === 200) {
              this.$message({type: 'success', message: res.data.msg});
              if (!this.isUpdate) {
                this.form = {};
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
          });



        } else {
          return false;
        }
      });
    },
    // 关闭
    resetForm(ruleForm) {
      // 调用父组件方法 隐藏弹框
      this.openEdit(null);
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit("update:visible", value);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-collapse {
  border: 1px solid #f6f6f6;
  .el-collapse-item {
    .topic_content {
      background: #fafafa;
      padding-top: 34px;
      .generalization {
        min-height: 80px;
      }
    }
  }
}
/* 选中后的字体颜色 */
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #2da636 !important;
  white-space: normal;
}
/* 选中后小圆点的颜色 */
::v-deep .el-radio__input.is-checked .el-radio__inner {
  background: #2da636 !important;
  border-color: #a7e8ac !important;
}
::v-deep .text .el-radio__input.is-checked + .el-radio__label {
  color: #e80a08 !important;
  white-space: normal;
}
::v-deep .el-radio__label {
  white-space: normal;
}
::v-deep .text .el-radio__input.is-checked .el-radio__inner {
  background: #e80a08 !important;
  border-color: #e80a08 !important;
}
</style>
